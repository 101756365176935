<template>
  <!-- 俱乐部大屏 -->
  <div class="club">
    <div class="top">
      <div class="left">
        <div>
          <p><img :src="info.clubLogo" alt="" /></p>
          <div>
            <h2>{{ info.clubName }}</h2>
            <div class="basic">
              <p class="basic-img">
                <img src="../../assets/club/user.png" alt="" />{{
                  info.responsiblePerson
                }}
              </p>
              <p class="basic-img">
                <img src="../../assets/club/phone.png" alt="" />{{
                  info.responsiblePersonPhone
                }}
              </p>
            </div>
            <p class="basic-img">
              <img src="../../assets/club/loaction.png" alt="" />{{
                info.cityPathName
              }}-{{ info.specificAddress }}
            </p>
          </div>
        </div>
        <p class="notice">
          <img src="../../assets/club/notice.png" alt="" />温馨提示: 请在
          {{ info.clubName }} 附近1公里使用 “天天拳击” 微信小程序
        </p>
      </div>
      <div>
        <div class="weather-box">
          <i :class="`iconfont ${icon}`"></i>
          <div class="weather">
            <p>{{ leftName }}</p>
            <p>
              <span>{{ temperature }}℃</span> <span>{{ weather }}</span>
            </p>
          </div>
        </div>
        <div class="time">
          <p>
            {{ $moment(time).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
        </div>
        <div class="full" v-show="isFull" @click="goFull(true)">
          <img src="../../assets/club/full.png" alt="" />
        </div>
        <div class="full" v-show="!isFull" @click="goFull(false)">
          <img src="../../assets/club/esc.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line">拳击热力榜</div>
    <div class="swiper swiper_list" v-if="AllList.length > 0">
      <div class="swiper-wrapper">
        <ul class="swiper-slide" v-for="(item, index) in AllList" :key="index">
          <li v-for="item1 in item" :key="item1.id">
            <div class="user">
              <p><img :src="item1.headImage" alt="" /></p>
              <div>
                <h2>
                  {{ item1.nickname }}
                  <img
                    :src="
                      item1.sex === 0
                        ? require('../../assets/club/girl.png')
                        : require('../../assets/club/boy.png')
                    "
                    alt=""
                  />
                </h2>
                <p>{{ item1.createdTime }}</p>
              </div>
              <span>消耗卡路里: - </span>
            </div>
            <div class="data">
              <div>
                <p>
                  训练时长:<span>{{ item1.trainDurationFormat }}</span>
                </p>
                <p>
                  最大力值:<span>{{ item1.maximumForceValue }}kg</span>
                </p>
                <p>
                  平均力值:<span>{{ item1.averageForceValue }}kg</span>
                </p>
              </div>
              <div>
                <p>
                  总打击次数:<span>{{ item1.total }}</span>
                </p>
                <p>
                  最快拳速:<span>{{ item1.fastestSpeed }}s</span>
                </p>
                <p>
                  平均拳速:<span>{{ item1.averageSpeed }}s</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="nodata">
      <p><img src="../../assets/club/nodata.png" alt="" /></p>
      <h4>暂无数据</h4>
    </div>
  </div>
</template>

<script>
var interval;
var refreshInfo;
var refreshList;
import AMap from "AMap";
import { Base64 } from "js-base64";
import { getClubInfo, getFreedomList } from "@/api";
import "swiper/swiper.scss";
import Swiper, { Autoplay, Pagination } from "swiper";
Swiper.use([Autoplay, Pagination]);
export default {
  data() {
    return {
      leftName: "",
      temperature: 0,
      weather: "晴",
      icon: "icon-qing",
      time: this.$moment(),
      clubId: this.$route.params.id,
      info: {},
      // list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      AllList: [],
      area: null,
      mySwiper: null,
      isFull: true,
    };
  },
  async created() {
    interval = setInterval(() => (this.time = new Date()), 1000);
    const decode = await Base64.decode(this.$route.params.id); // 解码
    // console.log("插件解密后", decode);
    this.clubId = Number(decode);
    this.init();
  },
  mounted() {
    refreshInfo = setInterval(() => {
      this.initWeather(this.area);
    }, 1000 * 2 * 3600);
    refreshList = setInterval(() => {
      this.getList(this.info.latitude, this.info.longitude);
    }, 1000 * 300);
  },
  destroyed() {
    clearInterval(interval);
    clearInterval(refreshInfo);
    clearInterval(refreshList);
  },
  methods: {
    // 获取基础信息
    async init() {
      const res = await getClubInfo({ id: this.clubId });
      // console.log("res :>> ", res);
      const { result } = res;
      this.info = result;
      this.getList(result.latitude, result.longitude);
      const that = this;
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({});

        let lnglat = [result.longitude, result.latitude];

        geocoder.getAddress(lnglat, function (status, res1) {
          if (status === "complete" && res1.info === "OK") {
            // result为对应的地理位置详细信息
            // console.log(res1);
            const area = res1.regeocode.addressComponent.district;
            that.area = area;
            that.initWeather(area);
          }
        });
      });
    },
    // 获取列表
    async getList(latitude, longitude) {
      const params = {
        clubId: this.clubId,
        latitude,
        longitude,
        pageNum: 1,
        pageSize: 50,
      };
      const res = await getFreedomList(params);
      // console.log("res :>> ", res);
      // this.list = res.result.records;
      const arr = await this.chunkArray(res.result.records, 9);
      // console.log("arr :>> ", arr);
      this.AllList = arr;
      this.initSwiper();
    },
    chunkArray(array, size) {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    },
    // 获取天气
    initWeather(area) {
      // debugger
      const _this = this;
      AMap.plugin("AMap.Weather", function () {
        //创建天气查询实例
        var weather = new AMap.Weather();
        //执行实时天气信息查询
        weather.getLive(area, function (err, data) {
          let iconfont = "icon-tianqiyubao";
          if (!err) {
            console.log("data :>> ", data);
            if (data.weather.indexOf("阴") !== -1) iconfont = "icon-yin";
            if (data.weather.indexOf("晴") !== -1) iconfont = "icon-qing";
            if (data.weather.indexOf("云") !== -1) iconfont = "icon-duoyun";
            if (data.weather.indexOf("雨") !== -1) iconfont = "icon-yu";
            if (data.weather.indexOf("雪") !== -1) iconfont = "icon-zhenxue";
            if (data.weather.indexOf("风") !== -1) iconfont = "icon-feng";
            if (data.weather.indexOf("霾") !== -1) iconfont = "icon-mai";
            if (data.weather.indexOf("雾") !== -1) iconfont = "icon-wu";
            if (data.weather.indexOf("沙") !== -1)
              iconfont = "icon-qiangshachenbao";
            _this.icon = iconfont;
            _this.temperature = data.temperature;
            _this.weather = data.weather;
            _this.leftName = data.city;
          }
        });
      });
    },
    initSwiper() {
      if (this.mySwiper) {
        this.mySwiper.destroy(true);
      }
      this.$nextTick(() => {
        if (this.AllList && this.AllList.length > 1) {
          this.mySwiper = new Swiper(".swiper_list", {
            autoplay: {
              delay: 10000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            },
            direction: "horizontal",
            slidesPerView: "auto",
            loop: true,
            speed: 2000,
          });
        }
        if (this.AllList && this.AllList.length <= 1) {
          this.mySwiper = new Swiper(".swiper_list", {
            autoplay: {
              delay: 10000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            },
            direction: "horizontal",
            slidesPerView: "auto",
            speed: 2000,
          });
        }
      });
    },
    goFull() {
      if (!document.fullscreenElement) {
        this.isFull = false;
        this.enterFullScreen();
      } else {
        this.isFull = true;
        this.exitFullScreen();
      }
    },
    enterFullScreen() {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element.msRequestFullscreen();
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.club {
  background: url("../../assets/club/bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  overflow: hidden;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 36px 40px 15px;
    background: rgba(93, 71, 164, 0.49);
    border: 1px solid #ffffff;
    .left {
      > div {
        display: flex;
        align-items: center;
        > p {
          width: 89px;
          height: 94px;
          border-radius: 9px;
          border: 1px solid #ffffff;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 9px;
          }
        }
        h2 {
          font-size: 20px;
          color: #ffffff;
          line-height: 27px;
          font-weight: 600;
        }
        .basic {
          display: flex;
          align-items: center;
          margin: 6px 0;
        }
        .basic-img {
          display: flex;
          align-items: center;
          font-size: 20px;
          color: #ffffff;
          line-height: 27px;
          margin-right: 22px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
      .notice {
        letter-spacing: 2px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        font-size: 25px;
        color: #ffffff;
        line-height: 34px;
        img {
          width: 28px;
          height: 24px;
          margin-right: 6px;
        }
      }
    }
    .weather-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .iconfont {
        font-size: 48px;
        color: white;
        margin-right: 10px;
      }
      .weather {
        color: white;
        p {
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 4px;
          margin-top: 4px;
          span {
            font-size: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .time {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      color: white;
      margin: 20px 0;
    }
    .full {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .line {
    padding: 15px 0;
    text-align: center;
    font-size: 28px;
    color: #ffffff;
    line-height: 38px;
    background: #5d47a4;
    margin: 22px 0;
  }

  ul {
    padding: 0 38px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc((100% - 120px) / 3);
      margin-right: 58px;
      margin-bottom: 36px;
      background: #5d47a4;
      border-radius: 13px;
      .user {
        display: flex;
        padding: 20px 0 10px 21px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        // align-items: center;
        > p {
          width: 70px;
          height: 72px;
          border-radius: 6px;
          border: 1px solid #ffffff;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
        > div {
          height: 72px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 0;
          flex: 1;
          h2 {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: #ffffff;
            line-height: 27px;
            img {
              width: 16px;
              height: 16px;
              margin-left: 16px;
            }
          }
          p {
            font-size: 20px;
            color: #e4dcff;
            line-height: 27px;
          }
        }
        > span {
          display: block;
          color: #ffffff;
          padding-right: 20px;
          font-size: 20px;
          color: #ffffff;
          line-height: 27px;
        }
      }
      .data {
        padding: 17px 21px 15px;
        div {
          display: flex;
          align-items: center;
          p {
            font-size: 20px;
            color: #e4dcff;
            line-height: 27px;
            display: flex;
            align-items: center;
            width: calc(58% / 2);
            margin-left: 20px;
            span {
              display: block;
              font-size: 20px;
              color: #ffffff;
              line-height: 27px;
              font-weight: bold;
              margin-left: 5px;
            }
          }
          p:first-child {
            width: 40%;
            margin-left: 0;
          }
        }
        div:first-child {
          margin-bottom: 21px;
        }
      }
    }
    li:nth-child(3n) {
      margin-right: 0;
    }
  }
  .nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 148px;
    p {
      width: 580px;
      height: 340px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      font-size: 28px;
      color: #ffffff;
      line-height: 38px;
      font-weight: bold;
    }
  }
}
</style>
